footer {
    background-color: #000;
    margin-top: 18px;
    padding: 4px 0;
    font-size: 0;
    .wrap {
        @include elastic-column;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .copyright {
            text-align: center;
            font-size: 12px;
            color: #ddd;
                a {
                color: #ddd;
                text-decoration: underline;
            }
        }
        .social {
            padding: 12px 0;
        }
    }
}