$gp-red: #ed1f27;
$gp-grey: #ddd;
$gp-black: #333;

$site-max-width: 1280px;
$width-double-column: 961px;
$width-wide-sidebar: 1161px;
$width-wide-header: 641px; // show social icons at header

$percentage-width: 96; // elastic-column width on narrow screens, without '%'

@mixin elastic-column {
    width: percentage($percentage-width/100);
    @media screen and (min-width: $site-max-width/$percentage-width*100) {
        width: $site-max-width;
    }
}

@mixin left-justify {
    text-align: left;
    @media screen and (min-width: $width-wide-header) {
        text-align: justify;
    }
}

@mixin center-left {
    text-align: center;
    @media screen and (min-width: $width-wide-header) {
        text-align: left;
    }
}

@import 'fonts';

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Ubuntu', 'UbuntuFallback', Tahoma, sans-serif;
    font-size: 15px;
    line-height: 1.4;
}

a {
    color: $gp-red;
    text-decoration: none;
    text-decoration-skip-ink: none;
    &:hover {
        color: $gp-black;
        text-decoration: underline;
    }
}

h1, h2 {
    font-family: 'Philosopher', 'PhilosopherFallback', Georgia, serif;
    font-weight: 400;
    color: $gp-black;
}

img {
    height: auto;
}

figure {
    margin: 0;
    padding: 0;
    &.full-width,
    &.single-image {
        img {
            width: 100%;
        }
    }
}

.hamburger {
	padding: 15px 15px 15px 0;
    @media screen and (min-width: $width-wide-header - 1) {
        padding: 0 15px 15px 0;
    }
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	&:hover {
		opacity: 0.7;
	}
    &.is-active {
        &:hover {
            opacity: 0.7;
        }
        .hamburger-inner {
            background-color: #aaa;
            &::before {
                background-color: #aaa;
            }
            &::after {
                background-color: #aaa;
            }
        }
    }
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}
.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
	width: 24px;
	height: 3px;
	background-color: #aaa;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
	&::before {
		width: 24px;
		height: 3px;
		background-color: #aaa;
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
		content: "";
		display: block;
		top: -8px;
	}
	&::after {
		width: 24px;
		height: 3px;
		background-color: #aaa;
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
		content: "";
		display: block;
		bottom: -8px;
	}
}

.hamburger--spin {
	.hamburger-inner {
		transition-duration: 0.22s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		&::before {
			transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
		}
		&::after {
			transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}
    &.is-active {
        .hamburger-inner {
            transform: rotate(225deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}

#top-nav {
    @media screen and (min-width: $width-wide-header) {
        display: none;
    }
    #nav-block {
        display: none;
        margin-bottom: 32px;
        padding-bottom: 18px;
        border-bottom: 1px solid #ccc;
        .header {
            font-size: 120%;
            margin-top: 16px;
        }
        ul#page-articles {
            padding-left: 18px;
        }
    }
}

.video-wrap {
    position: relative;
    cursor: pointer;
    font-size: 0;
    .playbutton {
        pointer-events: none;
        opacity: .5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 60px;
        z-index: 9;
        background: url('/style/images/video-playbutton.png') center no-repeat;
        transition: transform .3s;
    }
    .caption {
        pointer-events: none;
        font-size: 12px;
        box-sizing: border-box;
        padding: 10px 18px 18px;
        position: absolute;
        top: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,.6) 40%, rgba(94,45,30,0) 100%);
        color: #ddd;
        text-align: center;
    }
    &.playing {
        .playbutton {
            background: none;
        }
        .caption {
            display: none;
        }
    }
    &:hover {
        .playbutton {
            transform: scale(1.4) translate(-33%, -33%);
        }
    }
    video {
        width: 100%;
    }
}

video, audio {
    max-width: 100%;
}

.audio-wrap {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    @media screen and (min-width: 641px) {
        flex-direction: row;
    }
    .cover {
        @media screen and (min-width: 641px) {
            padding-right: 20px;
        }
        width: 200px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .audio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        .title {
            font-weight: 700;
            margin-bottom: 12px;
        }
        audio {
            width: 100%;
            border-radius: 40px;
        }
    }
}

.slider {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }
    &:hover {
        .slick-prev,
        .slick-next {
            opacity: .7;
        }
    }
}

.social {

    // Common for header and footer

    white-space: nowrap;
    .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
        img {
            transition: transform .3s ease;
            width: 100%;
            height: auto;
            opacity: .9;
        }
        &:hover img {
            transform: scale(1.1);
            opacity: 1;
        }
    }
}

.taxonomies {
    margin-top: 12px;
    margin-bottom: 36px;
    font-size: 13px;
    padding: 6px 9px 8px;
    background: #eee;
    color: #999;
    border-bottom: 1px solid #555;
    a {
        color: #222;
        white-space: nowrap;
        line-height: 1.75;
        text-decoration: none;
        border-bottom: 1px dotted #bbb;
        &:nth-child(even) {
            color: #833;
        }
        &:hover {
            color: $gp-red;
            border-bottom: 1px dotted $gp-red;
        }
    }
    .categories {
        @include center-left;
    }
    .tags {
        @include center-left;
        margin-top: 2px;
    }
}

.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 18px;
    right: 18px;
    width: 40px;
    height: 40px;
    font-size: 0;
    background: url('/style/images/scroll-to-top-icon-inverse.png');
    background-size: contain;
    cursor: pointer;
    opacity: .5;
    &:hover {
        opacity: .9;
    }
}

@import 'header';

main {
    @import 'aside';
    display: flex;
    flex-direction: column;
    @include elastic-column;
    margin: 6px auto 12px;
    @media screen and (min-width: $width-wide-header) {
        margin: 32px auto 12px;
    }
    @media screen and (min-width: $width-double-column) {
        flex-direction: row;
        justify-content: space-between;
    }
    .content {
        width: 100%;
        @media screen and (min-width: $width-double-column) {
            padding-left: 40px;
            background: url(images/content-line.png) repeat-y;
        }
        @import 'smart-columns';
        @import 'article';
        .pages-navigation {
            ul.pagination {
                box-sizing: border-box;
                width: 100%;
                list-style-type: none;
                padding: 0;
                margin: 0;
                text-align: center;
                display: flex;
                justify-content: stretch;
                li {
                    flex-grow: 1;
                    display: inline-block;
                    width: 100%;
                    font-weight: 700;
                    .page-link, &.page-item.active {
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.page-item.disabled {
                        max-width: 48px;
                    }
                    a {
                        box-sizing: border-box;
                        display: inline-block;
                        width: 100%;
                        font-size: 14px;
                        padding: 4px 8px;
                        // border: .1rem solid #ccc;
                        // background-color: #f4f4f4;
                        &:hover {
                            background-color: #f4f4f4;
                        }
                    }
                    &.active {
                        color: #ddd;
                    }
                    &.step {
                        max-width: 48px;
                        a {
                            img {
                                width: 12px;
                                height: 12px;
                                opacity: .8;
                            }
                            &:hover {
                                img {
                                opacity: .6;
                                position: relative;
                                }
                                &.prev img {
                                    left: -2px;
                                }
                                &.next img {
                                    left: 2px;
                                }
                            }
                            &:active {
                                img {
                                    position: relative;
                                    top: 1px;
                                    left: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .read-more {
        margin: 12px 0 18px;
        img {
            position: relative;
            top: 2px;
            opacity: .85;
            transition: transform .3s ease;
        }
        a {
            text-decoration: none;
            font-weight: 700;
            span.underline {
                padding-bottom: 2px;
                border-bottom: 1px dotted #777;
            }
            &:hover {
                span.underline {
                    position: relative;
                    top: -1px;
                }
                img {
                    opacity: 1;
                    transform: translateX(3px) scale(1.2);
                }
            }
        }
    }
    .thematic-header {
        display: flex;
        background-color: #eee;
        padding: 6px 18px 10px;
        margin-bottom: 24px;
        h1 {
            color: #999;
            font-size: 28px;
            margin: 12px 0;
            line-height: 1;
            span {
                color: #bbb;
                font-size: 85%;
            }
        }
        p {
            color: #bbb;
            margin-bottom: 0;
            text-transform: uppercase;
            span {
                color: #999;
            }
        }
        p.count {
            margin-top: 4px;
            span {
                font-size: 130%;
                line-height: 1;
            }
        }
        #top-nav {
            padding-top: 15px;
        }
        .hamburger {
            padding: 0 15px 15px 0;
            @media screen and (min-width: $width-wide-header - 1) {
                padding: 0 15px 15px 0;
            }
        }
        &.expanded {
            flex-direction: column;
            padding-bottom: 24px;
            #nav-block {
                margin-bottom: 16px;
            }
            p {
                margin-top: 0;
            }
        }
    }
}
#filters-menu {
    margin-top: 24px;
    h2 {
        text-align-last: left;
        font-size: 15px;
        margin: 0;
        padding: 4px 12px 4px 0;
        display: inline-block;
        user-select: none;
        line-height: 24px;
        font-family: Ubuntu, Tahoma, sans-serif;
        @media screen and (min-width: $width-wide-header) {
            font-size: 18px;
        }
        span.count {
            font-family: Ubuntu, Tahoma, sans-serif;
            font-weight: 400;
            font-size: 80%;
            color: #aaa;
        }
        a{ 
            color: $gp-black;
        }
        &.open{
            a {
                font-size: 120%;
                font-weight: 700;
                color: $gp-red;
            }
        }
    }
}
#filters {
    box-sizing: border-box;
    font-size: 0;
    margin: 16px 0 24px;
    [id^=taxonomy-list-] {
        display: none;
    }
    .list {
        margin-bottom: 18px;
        .divider {
            font-size: 14px;
            font-weight: 700;
            background-color: #e8e8e8;
            padding: 4px 12px;
            margin: 8px 0 4px;
        }
        .items { // Там, где идет разделение по буквам, это контейнер для непрерывного списка тегов
            display: flex;
            flex-wrap: wrap;
            span.item {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 50%;
                @media screen and (min-width: 680px) {
                    width: 33.333%
                }
                @media screen and (min-width: 768px) {
                    width: 25%
                }
                @media screen and (min-width: 1000px) {
                    width: 20%
                }
                input[type=checkbox] {
                    transform: scale(1.2);
                    position: relative;
                    top: 0px;
                    left: 2px;
                }
                label {
                    user-select: none;
                    font-size: 14px;
                    line-height: 1.2;
                    margin-left: 12px;
                    cursor: pointer;
                }
                padding: 4px 4px 4px 0;
            }
        }
    }
}

#filters-active {
    display: none;
    justify-content: stretch;

    &.open {
        display: flex;
    }
    margin-bottom: 24px;
    background-color: #ddd;
    box-sizing: border-box;
    position: relative;
    #filters-active-block {
        flex-grow: 1;
        display: inline-block;
        padding: 12px;
        .header, #filters-active-list, label.item {
            display: inline-block;
        }
        .header {
            padding: 4px 0;
        }
        label.item {
            padding: 4px 12px 4px 0;
            cursor: pointer;
            &:nth-child(even) {
                color: #933;
            }
        }
    }
    #filters-mode {
        flex-grow: 0;
        cursor: pointer;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 8px 12px;
        height: 36px;
        width: 36px;
        img {
            position: absolute;
            width: 100%;
        }
    }
}

#articles-list {
    display: block;
    @media screen and (min-width: $width-wide-header - 1) {
        display: flex;
        flex-wrap: wrap;
    }
    * {
        box-sizing: border-box;
    }
    .item {
        border: 1px solid transparent;
        position: relative;
        display: flex;
        align-items: center;
        margin: 4px 0;
        font-size: 0;
        &:hover {
            border: 1px solid #ddd;
            background-color: #f4f4f4;
        }
        .image {
            flex-shrink: 0;
            width: 64px;
            height: 64px;
            font-size: 0;
            img {
                width: 100%;
            }
            @media screen and (min-width: $width-wide-header - 1) {
                display: block;
                width: 96px;
                height: 96px;
            }
            @media screen and (min-width: 1000px) {
                display: block;
                width: 120px;
                height: 120px;
            }
        }
        @media screen and (min-width: $width-wide-header - 1) {
            margin: 8px 0;
            width: 50%;
        }
        @media screen and (min-width: 860px) {
            margin: 10px 0;
            width: 33.333%;
        }
        @media screen and (min-width: 1200px) {
            margin: 12px 0;
            width: 25%;
        }
        .title {
            padding: 0 14px;
            font-size: 15px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            a {
                display: flex;
                align-items: center;
                height: 100%;
                color: #333;
                &:hover {
                    color: $gp-red;
                }
            }
        }
    }
}

#taxonomy-list {
    .tag {
        display: inline-block;
        margin: 2px 8px 2px 0;
        span {
            font-size: 85%;
            color: #888;
        }
    }
}

@import 'footer';

.full-width {
    width: 100%!important;
}

.center {
    text-align: center!important;
}
.left {
    text-align: left!important;
}
.right {
    text-align: right!important;
}
.justify {
    text-align: justify!important;
}

.show-desktop-block {
    display: none;
    @media screen and (min-width: $width-wide-header) {
        display: block;
    }
}
.show-mobile-block {
    display: block;
    @media screen and (min-width: $width-wide-header - 1) {
        display: none;
    }
}
.show-desktop-inline-block {
    display: none;
    @media screen and (min-width: $width-wide-header) {
        display: inline-block;
    }
}
.show-mobile-inline-block {
    display: inline-block;
    @media screen and (min-width: $width-wide-header - 1) {
        display: none;
    }
}
.show-desktop-inline {
    display: none;
    @media screen and (min-width: $width-wide-header) {
        display: inline;
    }
}
.show-mobile-inline {
    display: inline;
    @media screen and (min-width: $width-wide-header - 1) {
        display: none;
    }
}

.b16 {
    margin-bottom: 16px!important;
}

.t16 {
    margin-top: 16px!important;
}

.b24 {
    margin-bottom: 24px!important;
}

.t24 {
    margin-top: 24px!important;
}