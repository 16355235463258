@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: 'PhilosopherFallback';
    src: url('fonts/phil-r-webfont.eot');
    src: url('fonts/phil-r-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/phil-r-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PhilosopherFallback';
    src: url('fonts/phil-b-webfont.eot');
    src: url('fonts/phil-b-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/phil-b-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PhilosopherFallback';
    src: url('fonts/phil-i-webfont.eot');
    src: url('fonts/phil-i-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/phil-i-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'PhilosopherFallback';
    src: url('fonts/phil-bi-webfont.eot');
    src: url('fonts/phil-bi-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/phil-bi-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'UbuntuFallback';
    src: url('fonts/ubuntu-r-webfont.eot');
    src: url('fonts/ubuntu-r-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-r-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'UbuntuFallback';
    src: url('fonts/ubuntu-m-webfont.eot');
    src: url('fonts/ubuntu-m-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-m-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'UbuntuFallback';
    src: url('fonts/ubuntu-ri-webfont.eot');
    src: url('fonts/ubuntu-ri-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-ri-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'UbuntuFallback';
    src: url('fonts/ubuntu-mi-webfont.eot');
    src: url('fonts/ubuntu-mi-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-mi-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
