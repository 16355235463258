.smart-columns {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .reverse {
    /* Add to FIRST column to make it second on mobile screen */
    order: 1;
  }

  [class^="col-"] {
    /* Sometimes inner objects (sliders) tend to blow up width if not defined */
    width: 100%;
  }

  @media screen and (min-width: $width-double-column) {

    flex-direction: row;
    justify-content: space-between;

    .col-70 {
      width: 67%;
    }

    .col-50-33 {
      width: 45%;
    }

    .col-60 {
      width: 57%;
    }

    .col-50 {
      width: 47.5%;
    }

    .col-40 {
      width: 37%;
    }

    .col-30 {
      width: 28%;
    }

    .col-33 {
      width: 31%;
    }

    .col-50-25 {
      width: 45%;
    }

    .reverse {
      order: 0;
    }
  }

  @media screen and (min-width: $width-double-column) {
    .col-50-33 {
      width: 31%;
    }

    .col-50-25 {
      width: 23%;
    }
  }
}