article, section {
    h2 {
        @include center-left;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        margin: 18px 0 12px;
        @media screen and (min-width: $width-wide-header) {
            font-size: 24px;
            margin: 32px 0 8px;
        }
        a {
            text-decoration: none;
            color: $gp-black;
            &:hover {
                color: $gp-red;
                text-decoration: none;
                border-bottom: 1px dotted #bbb;
            }
        }
    }
    h1, h2.list-title {
        @include center-left;
        font-size: 32px;
        font-weight: 400;
        line-height: 1;
        margin: 12px 0;
        @media screen and (min-width: $width-wide-header) {
            margin: 8px 0;
        }
        a {
            text-decoration: none;
            color: $gp-black;
            &:hover {
                color: $gp-red;
                text-decoration: none;
                border-bottom: 1px dotted #bbb;
            }
        }
        small {
            font-size: 50%;
            font-family: Ubuntu, Tahoma, sans-serif;
            color: #555;
        }
    }
    h3 {
        margin: 18px 0 10px;
        @media screen and (min-width: $width-wide-header) {
            margin: 24px 0 8px;
        }
    }
    p {
        text-align: justify;
        margin: 12px 0;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    blockquote {
        margin: 12px 28px 18px;
        &::before {
            font-family: 'Philosopher', 'PhilosopherFallback', Georgia, serif;
            font-size: 50px;
            position: relative;
            top: -10px;
            left: -26px;
            display: block;
            height: 0;
            content: '\201C';
            color: #aaa;
        }
        p {
            margin: 8px 0;
        }
    }
    .credits {
        padding: 6px 9px;
        color: #888;
        background-color: #eee;
        margin-bottom: 8px;
        a {
            color: #222;
        }
        .publish {
            @include center-left;
            padding: 0 4px;
            font-size: 13px;
        }
        .author-name {
            font-size: 0;
        }
        time {
            white-space: nowrap;
        }
        .event {
            font-size: 13px;
            padding-bottom: 6px;
            margin-top: 6px;
            button {
                @include center-left;
                position: relative;
                width: 100%;
                background: #f8f8f8;
                background: linear-gradient(to bottom,#f8f8f8 0%,#e0e0e0 100%);
                border: 1px solid #d3d3d3;
                padding: 2px 9px;
                cursor: pointer;
                box-shadow: 0 0 7px rgba(0, 0, 0, .1);
                user-select: none;
                &:hover {
                    top: -1px;
                    border: 1px solid #c6c6c6;
                    background: #fcfcfc;
                    background: linear-gradient(to bottom,#fcfcfc 0%,#e8e8e8 100%);
                }
                &:active, &:focus {
                    outline: none;
                }
                &:active {
                    border: 1px solid #a6a6a6;
                }
                &.open {
                    color: #888;
                }
            }
            .event-info {
                display: none;
                text-align: left;
                padding: 8px 6px 0;
                color: #222;
                span {
                    color: #888;
                }
                p {
                    margin: 0 0 6px;
                    text-align: left;
                }
                ul {
                    margin: 0 0 8px;
                    padding: 0;
                    list-style-type: circle;
                    list-style-position: inside;
                    li {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
    figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        figcaption {
            font-size: 12px;
            box-sizing: border-box;
            padding: 36px 24px 8px;
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(0deg, rgba(0,0,0,.7) 40%, rgba(94,45,30,0) 100%);
            color: #ddd;
            text-align: center;
        }
        a {
            font-size: 0;
        }
        &.narrow {
            @media screen and (min-width: $width-wide-header - 1) {
                a, img, figcaption {
                    width: 60%;
                }
                a img {
                    width: 100%;
                }
            }
        }
    }
    .folding {
        background: #eee;
        margin-bottom: 12px;
        .folding-header {
            margin: 0;
            padding: 6px 12px 8px;
            cursor: pointer;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.1;
            &::before {
                content: url('/style/images/gp-arrow-right.png');
                padding-right: 8px;
                display: inline-block;
                width: 17px;
                height: 17px;
                position: relative;
                top: 2px;
                transition-duration: .5s;
            }
            &:hover {
                color: $gp-red;
            }
        }
        &.open {
            .folding-header {
                background: #ddd;
                &::before {
                    transform: rotate(90deg);
                    position: relative;
                    top: 8px;
                    left: -4px;
                }
            }
        }
        .folding-content {
            display: none;
            font-size: 13px;
            padding: 6px 12px 12px;
            ul {
                margin: 0;
                padding-left: 18px;
            }
        }
    }
    .featured-list {
        padding: 20px 22px;
        margin: 20px 0 10px;
        box-sizing: border-box;
        background-color: #eee;
        h3 {
            font-family: 'Philosopher', 'PhilosopherFallback', Georgia, serif;
            font-size: 22px;
            font-weight: 700;
            margin: 0 0 16px;
            padding-bottom: 2px;
            color: #555;
            border-bottom: 1px solid #555;
        }
        .featured {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            @media screen and (min-width: $width-wide-header) {
                flex-direction: row;
            }
            .image {
                flex-shrink: 0;
                width: 300px;
                height: 300px;
                margin-right: 20px;
                box-sizing: border-box;
                margin-bottom: 8px;
                img {
                    width: 100%;
                }
                @media screen and (min-width: $width-wide-header) {
                    width: 200px;
                    height: 200px;
                }
                }
            .announce {
                h4 {
                    margin: 0 0 8px;
                    font-weight: 400;
                    font-size: 22px;
                    font-family: 'Philosopher', 'PhilosopherFallback', Georgia, serif;
                    line-height: 1.1;
                }
                p {
                    @include left-justify;
                    margin-top: 0;
                }
            }
        }
    }
    .comments {
        h3 {
            font-family: 'Philosopher', 'PhilosopherFallback', Georgia, serif;
            font-size: 22px;
            font-weight: 700;
            margin: 0 0 16px;
            color: #555;
        }
        .comment {
            background-color: #eee;
            margin-bottom: 12px;
            border-bottom: 1px solid #aaa;
            padding: 12px 18px;
        }
        .name {
            font-weight: 700;
            margin-bottom: 0;
        }
        .date {
            color: #888;
            margin-top: 0;
        }
        .disclaimer {
            text-align: center;
            padding: 12px 0;
            color: #444;
            background: #eee;
            font-size: 13px;
        }
    }
    .float-left {
        @media screen and (max-width: $width-wide-header - 1) {
            width: auto!important;
        }
        @media screen and (min-width: $width-wide-header) {
            margin-right: 28px;
            float: left;
        }
    }
    .float-right {
        @media screen and (max-width: $width-wide-header - 1) {
            width: auto!important;
        }
        @media screen and (min-width: $width-wide-header) {
            margin-left: 28px;
            float: right;
        }
    }
}