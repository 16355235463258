aside {
    flex-shrink: 0;
    order: 1;
    margin-top: 32px;
    @media screen and (min-width: $width-double-column) {
        width: 300px;
        order: initial;
        margin-top: 0;
    }

    @media screen and (min-width: $width-wide-sidebar) {
        width: 360px;
    }
    .widget {
        margin-top: 36px;

        &:first-child {
            margin-top: 0;
        }

        position: relative;

        h2 {
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 6px;
            padding-bottom: 2px;
            color: #555;
            border-bottom: 1px solid #555;
            text-transform: uppercase;
        }

        &:before {
            position: absolute;
            top: -10px;
            left: -8px;
            content: url(images/gp-corner.png);
        }

        a {
            color: #444;
            text-decoration: none;
            border-bottom: 1px dotted #bbb;

            &:nth-child(even) {
                color: #933;
            }

            &:hover {
                color: $gp-red;
            }
        }

        &.chapters {
            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style-type: none;
                    word-wrap: break-word;
                    display: inline-block;
                    margin-right: 8px;
                    margin-top: 10px;
                    padding-bottom: 4px;
                    font-size: 15px;
                    line-height: 1.3;
                }
            }
        }
        &.info {
            ul {
                padding: 0;
                margin: 20px 0 0;

                li {
                    list-style-position: inside;
                    word-wrap: break-word;
                    margin-right: 8px;
                    margin-top: 10px;
                    padding-bottom: 4px;
                    font-size: 15px;
                    line-height: 1.3;
                }
            }
        }

        &.tags {
            .list {
                display: none;
                border-bottom: 1px solid #ccc;
                padding: 8px 0 12px;
                margin-bottom: 4px;

                .divider {
                    margin: 6px 0 4px;
                    padding: 4px 6px;
                    background: #eee;
                    color: #aaa;
                    font-size: 14px;
                    line-height: 1;
                }

                a {
                    display: inline-block;
                    margin-right: 6px;
                    font-size: 13px;
                }
            }

            h3 {
                font-family: 'Ubuntu', 'UbuntuFallback', Tahoma, sans-serif;
                display: inline-block;
                margin: 10px 10px 0 0;
                padding-bottom: 4px;
                font-weight: 400;
                font-size: 15px;
                line-height: 1.3;
                position: relative;

                span.count {
                    font-weight: 400;
                    font-size: 80%;
                    color: #999;
                }

                &:hover {
                    span.count {
                        color: $gp-black;
                    }
                }
            }
        }

        &.favorites {
            .slick-dotted.slick-slider {
                margin-bottom: 56px;
            }
            .slider {
                .slide {
                    position: relative;
                    img {
                        max-width: 100%;
                    }
                    .caption {
                        pointer-events: none;
                        box-sizing: border-box;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        font-size: 13px;
                        text-align: center;
                        color: #fff;
                        padding: 12px 8px 14px;
                        line-height: 1.1;
                        background: linear-gradient(0deg, rgba(0, 0, 0, .8) 40%, rgba(94, 45, 30, 0) 100%);
                        text-shadow: 0 0 3px #000;
                    }
                }
            }
        }
        &.page-contents {
            display: none;
            @media screen and (min-width: $width-double-column - 1) {
                display: block;
            }
            ul {
                padding-left: 18px;
                margin-bottom: 16px;
                li {
                    margin-bottom: 6px;
                }
            }
            nav {
                margin-bottom: 12px;
            }
        }
        &.debug {
            h3 {
                margin: 8px 0 2px;
            }
            ol {
                margin: 0;
                padding: 0 0 0 18px;
                li {
                    margin: 0;
                }
            }
            .status {
                margin-bottom: 6px;
            }
            #tags-errors {
                margin-bottom: 12px;
            }
        }
    }
}
