header {
    background-color: #000;
    font-size: 0;
    .wrap {
        @include elastic-column;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 18px 0;
        .logo {
            font-size: 0;
            position: relative;
            width: 280px;
            height: 41px;
            img {
                width: 100%;
            }
            @media screen and (min-width: $width-wide-header) {
                width: 300px;
                height: 44px;
            }
            @media screen and (min-width: $width-wide-sidebar) {
                width: 360px;
                height: 52px;
            }
            a {
                font-size: 0;
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
        @media screen and (min-width: $width-wide-header) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
        }
    }
}
